.div_pa{
    display: flex;
    justify-content: space-around;
    width: 100%;

}

.header_div {
    padding: 4rem 0 15rem;
    background-color: #efefef;
}

.h4_img{
    text-align: center;
}

.text_font {
    font-size: 1.5rem;
    color: #758594;
    margin-left: 3rem;
}