.about_div{
    padding: 4rem 0 6rem;
    background-color: #efefef;
}
.text_div{
    height: 100%;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.card_div{
    margin: 2rem 0;
}
.card_div{
    height: 30rem;
}
.careers_div{
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.careers_title {
    color: #007833;
    margin-bottom: 2rem;
    text-align: center;
}
.Partnerships{
    padding: 2rem 0 3rem 0 ;
    background-color: #EFEFEF;
}
.Partnerships .div{
    background-color: white;

}
.Partnerships .div .title{
    margin-top: 1rem;
}

.img_div .p_div {
    color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
}

.img_div {
    position: relative;
    width: 100%;
    height: 100%;
}

.img {
    width: 100%;
    /*display: inline-block;*/
    /*zoom: 1;*/
    /*letter-spacing: normal;*/
    /*word-spacing: normal;*/
    /*vertical-align: top;*/
    /*text-rendering: auto;*/
}

.career-item-overlay-text {
    position: absolute;
    top: calc(50% - 1rem);
    font-size: 2rem;
    font-weight: 400;
    color: #ffffff;
    width: 100%;
    text-align: center;
}

.careers_p{
    font-size: 1.5rem;
    color: #3F3F3F;
    padding: 5px;
}
.careers_p p{
    padding-top: 1rem;
}
.careers{
    border: 2px solid #BFBFBF;
    margin-bottom: 2rem;
}

.img_d{
    padding: 0;
}