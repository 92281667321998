#ContentDiv
{
    display: block;

}

#unfinished_div
{
    margin-left: 10%;
    top:0;
}

#unfinished_p
{
    margin-top:50px;
    display:inline-block;
}

.timeline
{
    position: relative;
    margin: 50px auto;
    padding-top: 50px;
    padding-bottom: 100px;
    display: block;
}

.timeline::before
{
    content: "";
    position: absolute;
    top: 0;
    left: 15%;
    margin-left: -2px;
    width: 4px;
    height: 100%;
    background-color: #8d94b1;
}

.timeline_item
{
    margin-bottom: 100px;
    position: relative;
    display: block;
    padding-right:10%
}

.time
{
    position: absolute;
    width:15%;
    text-align:right;
    top: 8px;
}

.time p
{
    padding-right:50px;
}

.timeline__item__station
{
    position: absolute;
    background-color: #0135A2;
    left: 15%;
    margin-left: -15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid #8d94b1;
    display: block;
}

.timeline__item__content
{
    margin-left:25%;
    padding: 30px 40px;
    background: #fff;
    border-radius: 6px;
    text-align:center;
    box-shadow: 5px 5px 5px 1px #777777;
}

.timeline__item__content::before
{
    position: absolute;
    content: '';
    left: 15%;
    top: 20px;
    height: 4px;
    margin-top: -2px;
    margin-left: 10px;
    background: #8d94b1;
    width: 10%;
    z-index: -1;
}

.timeline__item__content p
{
    margin-bottom:10px;
}

.timeline__item__content img
{
    width:50%;
    height:auto;
}