.header_div_member {
    padding: 8rem 0 4rem 0;
    background-color: white;
}

.div_mar {
    margin-top: 2rem;
}

.card_member {
    padding-bottom: 2rem;
    margin-bottom: 2rem;

}

pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    *word-wrap: break-word;
    *white-space: normal;
}

.div-fix {
    padding: 0 0 2rem 0;
}

@media screen and (min-width: 768px) {
    .div-fixed {
        position: fixed;
    }

}

.content { /* 限制容器宽度 */
    display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
    -webkit-box-orient: vertical; /* 垂直排列 */
    -webkit-line-clamp: 6; /* 限制显示三行 */
    overflow: hidden; /* 隐藏超出范围的内容 */
    text-overflow: ellipsis; /* 使用省略号 */
}
