.border_list{
    border-bottom: none;
}
.publication{
    padding: 3rem 0;
}
.public{
    display: flex;
    background-color: #E9ECEF;
    padding: 1rem;
    margin-top: 2rem;
}
.public_text{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1rem;
    /*align-items: center;*/
}
.public_img{
    width: 10rem;
}